




























































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { UnorderedList } from '@/types/cms/components';

/**
 * CMSAdminComponentUnorderedListFormDialog component
 * 
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component
export default class CMSAdminComponentUnorderedListFormDialog extends Vue {
    // Prop of type boolean that defined whether the dialog should be shown or not
    @Prop(Boolean) readonly showDialog: boolean | undefined;

    // Prop that holds the pageList data
    @Prop({type: Object as () => UnorderedList}) readonly editedListItem: UnorderedList | undefined;

    // Prop that holds the id of the edited pageList
    @Prop(Number) readonly editedListIndex: number | undefined;

    // An crud string that defines the mode of the dialog
    @Prop(String) readonly dialogMode: string | undefined;

    // Boolean that defines wheter the form data is valid or not
    private formValid = false;

    /**
     * @returns the title for the form
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    get formTitle(): string {
        return (this.dialogMode) ? this.$t('form.titles.' + this.dialogMode + 'List').toString() : '';
    }

    /**
     * Resets the form validation
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private resetFormValidation() {
        if (this.$refs.form) {
            (this.$refs.form as Vue & { resetValidation: () => void }).resetValidation();
        }
    }

    /**
     * Adds new item to list
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private addItem() {
        if (!this.editedListItem) return;

        // add item to array
        this.editedListItem.items.push('-');
    }

    /**
     * Adds new item to list
     *
     * @param itemIndex index
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private deleteItem(itemIndex: number) {
        if (!this.editedListItem || this.editedListItem.items.length < itemIndex) return;

        this.editedListItem.items.splice(itemIndex, 1);
    }

    /**
     * Saves/Updates or deletes the pageList
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private saveList() {
        if (this.editedListItem) {
            this.resetFormValidation();
            this.$emit('listSaved', this.dialogMode, this.editedListIndex, this.editedListItem);
        }
    }

    /**
     * Closes the dialog
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private closeDialog() {
        this.resetFormValidation();
        this.$emit('dialogClosed');
    }
}
